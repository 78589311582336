import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { CallToAction, Hero, Section, ProductCard, SEO } from "components"

const SolutionTemplate = ({
  data: {
    products: { edges: products },
    sanitySolution: { title, image, description },
  },
}) => {
  return (
    <>
      <SEO title={title} />

      <Hero
        title={title}
        image={image}
        description={
          description
            ? description
            : `Produtos da Bondmann Química para ${title}`
        }
        overlay
      />

      <Section>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map(({ node: { title, slug, department, image } }) => {
            return (
              <ProductCard
                key={slug.current}
                title={title}
                to={`/produtos/${slug.current}`}
                department={department}
                image={image}
              />
            )
          })}
        </div>
      </Section>

      <CallToAction />
    </>
  )
}

SolutionTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

export default SolutionTemplate

export const query = graphql`
  query GET_SOLUTION($slug: String) {
    sanitySolution(slug: { current: { eq: $slug } }) {
      title
      description
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    products: allSanityProduct(
      filter: { solutions: { elemMatch: { slug: { current: { eq: $slug } } } } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          department {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`
